import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import remark from 'remark'
import remarkHTML from 'remark-html'
import Banner from "../components/banner"
import AudioBlock from "../components/audio-block"

import practice1image from "../assets/practices/practice-01-cover.jpg"
import practice2image from "../assets/practices/practice-02-cover.jpg"
import practice3image from "../assets/practices/practice-03-cover.jpg"
import practice4image from "../assets/practices/practice-04-cover.jpg"
import practice5image from "../assets/practices/practice-05-cover.jpg"
import practice6image from "../assets/practices/practice-06-cover.jpg"
import practice7image from "../assets/practices/practice-07-cover.jpg"
import intoTheBodyImage from "../assets/practices/meditation-cover.jpg"

const toHTML = value => remark().use(remarkHTML).processSync(value).toString()

export default function Template({ data, pageContext }) {
  const { markdownRemark } = data 
  const { frontmatter } = markdownRemark

  const bannerUrl = "/practices/practices-banner.jpg";

  return (
    <Layout title="Online Practices" description="A collection of online practices that Anne has to offer.">
      <Banner title={frontmatter.title} imageUrl={bannerUrl}/>
      <section className="section">
        <div className="container">
          <h2 className="title is-2 has-text-centered has-text-tertiary">{frontmatter.section1Header}</h2>
          <div className="columns">
            <div className="column">
              <div className="content markdown" dangerouslySetInnerHTML={{__html: toHTML(frontmatter.rollingdescription)}} />
            </div>
            <div className="column">
              <div className="vimeo pink-border">
                <iframe src="https://player.vimeo.com/video/425199856" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section has-background-tertiary-faded">
        <div className="container">
          <h2 className="title is-2 has-text-centered has-text-tertiary">{frontmatter.section2Header}</h2>
          <div className="block">
            <div className="content has-text-secondary markdown" dangerouslySetInnerHTML={{__html: toHTML(frontmatter.practicesdescription)}} /> 
          </div>
          <div className="columns is-multiline is-variable is-1" style={{justifyContent: "center"}}>       
            <div className="column is-half is-one-quarter-desktop">
              <AudioBlock title="Practice 1" image={practice1image} source="/audio/Practice 1.mp3"/>
            </div>
            <div className="column is-half is-one-quarter-desktop">
              <AudioBlock title="Practice 2" image={practice2image} source="/audio/Practice 2.mp3"/>
            </div>
            <div className="column is-half is-one-quarter-desktop">
              <AudioBlock title="Practice 3" image={practice3image} source="/audio/Practice 3.mp3"/>
            </div>
            <div className="column is-half is-one-quarter-desktop">
              <AudioBlock title="Practice 4" image={practice4image} source="/audio/Practice 4.mp3"/>
            </div>
            <div className="column is-half is-one-quarter-desktop">
              <AudioBlock title="Practice 5" image={practice5image} source="/audio/Practice 5.mp3"/>
            </div>
            <div className="column is-half is-one-quarter-desktop">
              <AudioBlock title="Practice 6" image={practice6image} source="/audio/Practice 6.mp3"/>
            </div>
            <div className="column is-half is-one-quarter-desktop">
              <AudioBlock title="Practice 7" image={practice7image} source="/audio/Practice 7.mp3"/>
            </div>
          </div>
        </div>
      </section> 
      <section className="section">
        <div className="container">
          <h2 className="title is-2 has-text-centered has-text-tertiary">Meditations</h2>
          <div className="columns is-multiline is-variable is-1" style={{justifyContent: "center"}}>       
            <div className="column is-half is-one-quarter-desktop">
              <AudioBlock title="Into the Body" image={intoTheBodyImage} source="/audio/Into the Body.mp3"/>
            </div>
          </div>
        </div>
      </section>            
    </Layout>
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        slug
        title
        section1Header
        rollingdescription
        section2Header
        practicesdescription
      }
    }
  }
`