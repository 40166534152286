import React from "react"
import { graphql, Link } from "gatsby"

export default function AudioBlock(props) {
  return (
    <div className="audio-block block" style={{padding:"20px"}}>     
      <div className="block" style={{marginBottom:"6px"}}>
        <img className="fill inline pink-border" src={props.image} alt={props.title} />
      </div>

      <h4 className="title is-4 has-text-primary has-text-centered" style={{marginBottom:"12px"}}>{props.title}</h4>
      
      <div className="block">
        <audio style={{width:"100%"}} controls>
          <source src={props.source} type="audio/mp3" />
        </audio>
      </div>    
    </div>
  )
}